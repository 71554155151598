import React from 'react';
import '../assets/styles/main.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SeccionServicio from '../components/SeccionServicio';
import { FocusCardsDemo } from '../components/FocusCardsDemo';

function VistaServices() {
    return (
      <div className="bg_cont">
        <Header />
        <FocusCardsDemo />
        <Footer />
      </div>
    );
  }
  
  export default VistaServices;