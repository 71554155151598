import React from "react";
import "../assets/styles/FocusCardsDemo.css";
import image from "../../src/assets/images/diagrama.png";
import image2 from "../../src/assets/images/caja3.png";
import image3 from "../../src/assets/images/repo.png";
import image4 from "../../src/assets/images/migra.png";
import image5 from "../../src/assets/images/auto2.png";

export function FocusCardsDemo() {
    const cards = [
        {
            title: "Limpieza y orden de gabinetes",
            image: "https://blog.suileraltamirano.com/wp-content/uploads/2023/06/wed-e1685976436312.png",
            description: "Asegure mantenimientos rápidos y eficientes. Nosotros nos encargamos de mantener todo en perfecto orden, facilitando las intervenciones y el acceso a los componentes clave.",
            href: "/limpieza",
        },
        {
            title: "Diagramas eléctricos",
            image: image,
            description: "Visualice y entienda las conexiones eléctricas. Diagramas claros para garantizar instalaciones seguras y bien organizadas.",
            href: "/diagramasE",
        },
        {
            title: "Modelado en 3D",
            image: image2,
            description: "Transforme ideas en realidad con nuestros modelos tridimensionales precisos y detallados.",
            href: "/modelado3d",
        },
        {
            title: "Repositorios",
            image: image3,
            description: "Organice y acceda a sus proyectos fácilmente con nuestros repositorios estructurados y seguros.",
            href: "/repositorios",
        },
        {
            title: "Migración",
            image: image4,
            description: "Asegure una transición sin problemas con nuestras herramientas de migración confiables y eficientes.",
            href: "/migraciones",
        },
        {
            title: "Integraciones y automatizaciones",
            image: image5,
            description: "Optimice procesos y ahorre tiempo con integraciones y automatizaciones personalizadas.",
            href: "/automatizaciones",
        },
    ];

    return (
        <div className="grid-container">
            {cards.map((card, index) => (
                <a 
                    href={card.href} 
                    key={index} 
                    style={{ textDecoration: "none", color: "inherit" }}
                >
                    <div
                        className="card"
                        style={{
                            backgroundImage: `url(${card.image})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center"
                        }}
                        data-description={card.description}
                    >
                        {card.title}
                    </div>
                </a>
            ))}
        </div>
    );
}
