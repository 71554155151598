import React, { useState } from 'react';
import '../assets/styles/ContactForm.css';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';

function ContactForm() {
  const [status, setStatus] = useState(''); // Para manejar el estado del envío

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    try {
      const response = await fetch('https://formspree.io/f/mwpplgnk', {
        method: 'POST',
        body: formData,
        headers: {
          Accept: 'application/json',
        },
      });

      if (response.ok) {
        setStatus('success');
        form.reset();
      } else {
        setStatus('error');
      }
    } catch (error) {
      setStatus('error');
    }
  };

  return (
    <div className="contact-container">
      <div className="contact-info">
        <div className="contact-item">
          <FaMapMarkerAlt className="contact-icon" />
          <h3>Dirección</h3>
          <p>La Asunción de Belén<br />Heredia, Costa Rica</p>
        </div>
        <div className="contact-item">
          <FaPhoneAlt className="contact-icon" />
          <h3>Teléfono</h3>
          <p>+506 8863 - 4402<br />+506 7081 - 0116<br />+506 8359 - 7729</p>
        </div>
        <div className="contact-item">
          <FaEnvelope className="contact-icon" />
          <h3>Email</h3>
          <p>info@automatcer.com</p>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="contact-form">
        <h2>Formulario de Contacto:</h2>
        <div className="form-group">
          <label htmlFor="name">Nombre Completo <span>*requerido</span></label>
          <input type="text" id="name" name="name" required />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email <span>*requerido</span></label>
          <input type="email" id="email" name="email" required />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Teléfono <span>*requerido</span></label>
          <input type="tel" id="phone" name="phone" required />
        </div>
        <div className="form-group">
          <label htmlFor="message">Escribe tu solicitud <span>*requerido</span></label>
          <textarea id="message" name="message" rows="5" required></textarea>
        </div>
        <button type="submit" className="submit-button">ENVIAR</button>
      </form>

      {/* Mensajes de estado */}
      {status === 'success' && <p className="success-message">¡Formulario enviado con éxito!</p>}
      {status === 'error' && <p className="error-message">Hubo un error. Por favor, intenta nuevamente.</p>}
    </div>
  );
}

export default ContactForm;
